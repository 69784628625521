import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import Auth from '@aws-amplify/auth';
import '../index.css';
import {parseParams} from "../helpers/parseParams";
import { logo_colour, externalIdentityProvider } from "../helpers/tenantComponents";

const UserActivation = () => {
  const [formValues, setFormValues] = useState({password: "", password_confirmation: ""});
  const [isSubmitting, setSubmitting] = useState(false);
  const [error, setError] = useState(false);
  const { push, location } = useHistory();
  const { type } = parseParams(location.search);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    try {
      const { inviteCode, userId } = parseParams(location.search);
      const user = await Auth.signIn(userId, inviteCode);

      if (user.challengeName !== 'NEW_PASSWORD_REQUIRED') return;
      await Auth.completeNewPassword(user, formValues.password);

      return push("/")
    } catch (err) {
      setError(err.message);
    } finally {
      setSubmitting(false);
    }
  };

  if (type !== "internal") {
    return (
      <div className="min-h-screen bg-gray-50 flex flex-col py-32 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md mt">
        <img
          className="mx-auto h-14 w-auto"
          src={logo_colour}
          alt="login"
        />
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Register your account</h2>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          {externalIdentityProvider && (
            <button
              type="submit"
              className="w-full flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              onClick={() => Auth.federatedSignIn({ customProvider: externalIdentityProvider.id})}
            >
              {externalIdentityProvider.displayName}
            </button>
          )}
        </div>
      </div>
    </div>
    )
  }

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img
          className="mx-auto h-14 w-auto"
          src={logo_colour}
          alt="login"
        />
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Register your account</h2>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <form onSubmit={handleSubmit} className="space-y-6" action="#" method="POST">
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                Password
              </label>
              <div className="mt-1">
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="password"
                  onChange={(e) => setFormValues({...formValues, email: e.target.value})}
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
            </div>

            <div>
              <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                Password Confirmation
              </label>
              <div className="mt-1">
                <input
                  id="password_confirmation"
                  name="password_confirmation"
                  type="password"
                  autoComplete="current-password"
                  onChange={(e) => setFormValues({...formValues, password: e.target.value})}
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
            </div>
            {error && <div className="mt-2 text-sm text-red-600">{error}</div>}
            <div>
              <button
                type="submit"
                disabled={isSubmitting}
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50 disabled:cursor-not-allowed"
              >
                Register
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UserActivation;
