import React from 'react';
import ReactDOM from 'react-dom';
import './index.generated.css';
import App from './components/App';
import Auth from "@aws-amplify/auth";

Auth.configure({
  region: process.env.REACT_APP_AWS_REGION,
  userPoolId: process.env.REACT_APP_USER_POOL_ID,
  userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
  oauth: {
    domain: process.env.REACT_APP_COGNITO_DOMAIN,
    scope: ["email", "openid", "profile"],
    redirectSignIn: process.env.REACT_APP_COGNITO_REDIRECT,
    redirectSignOut: process.env.REACT_APP_COGNITO_REDIRECT,
    responseType: "code",
  },
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

