import clsx from "clsx";

const colors = [
  "bg-indigo-500",
  "bg-pink-500",
  "bg-purple-500",
  "bg-blue-500"
];

const Avatar = ({ initials, index = 0}) => (
  <div
    className={clsx(
      "w-8 h-8",
      "rounded-full text-white font-semibold cursor-pointer",
      "flex items-center justify-center",
      colors[index] || colors[0]
    )}
  >
    {initials}
  </div>
);

export default Avatar;