import {brokerCountries} from "../../helpers/tenantComponents";
import {maybeAddErrorStyle} from "../../helpers/maybeAddErrorStyle";
import clsx from "clsx";
import get from "lodash.get";

const AddressSubForm = ({ formValues, path, handleChange, errors }) => {
  return (
    <>
      <div className="col-span-6 sm:col-span-4">
        <label htmlFor="country" className="block text-sm font-medium text-gray-700">
          Country
        </label>
        <select
          id="country"
          name={`${path}.country`}
          autoComplete="country"
          value={get(formValues, `${path}.country`)}
          onChange={handleChange}
          className={clsx(
            maybeAddErrorStyle(`${path}.country`, errors),
            "mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          )}
        >
          {brokerCountries.map(c => <option value={c}>{c}</option>)}
        </select>
      </div>
      <div className="col-span-6">
        <label htmlFor="addressLine1" className="block text-sm font-medium text-gray-700">
          Address Line 1
        </label>
        <input
          type="text"
          name={`${path}.addressLine1`}
          id="addressLine1"
          autoComplete="addressLine1"
          value={get(formValues, `${path}.addressLine1`)}
          onChange={handleChange}
          className={clsx(
            maybeAddErrorStyle(`${path}.addressLine1`, errors),
            "mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
          )}
        />
      </div>
      <div className="col-span-6">
        <label htmlFor="addressLine2" className="block text-sm font-medium text-gray-700">
          Address Line 2
        </label>
        <input
          type="text"
          name={`${path}.addressLine2`}
          id="addressLine2"
          autoComplete="addressLine2"
          value={get(formValues, `${path}.addressLine2`)}
          onChange={handleChange}
          className={clsx(
            maybeAddErrorStyle(`${path}.addressLine2`, errors),
            "mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
          )}
        />
      </div>
      <div className="col-span-6 sm:col-span-2">
        <label htmlFor="city" className="block text-sm font-medium text-gray-700">
          City
        </label>
        <input
          type="text"
          name={`${path}.city`}
          id="city"
          value={get(formValues, `${path}.city`)}
          onChange={handleChange}
          className={clsx(
            maybeAddErrorStyle(`${path}.city`, errors),
            "mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
          )}
        />
      </div>

      <div className="col-span-6 sm:col-span-2">
        <label htmlFor="postCode" className="block text-sm font-medium text-gray-700">
          ZIP / Postal
        </label>
        <input
          type="text"
          name={`${path}.postCode`}
          id="postCode"
          autoComplete="postal-code"
          value={get(formValues, `${path}.postCode`)}
          onChange={handleChange}
          className={clsx(
            maybeAddErrorStyle(`${path}.postCode`, errors),
            "mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
          )}
        />
      </div>
      <div className="col-span-6 sm:col-span-2">
        <label htmlFor="region" className="block text-sm font-medium text-gray-700">
          Region / State
        </label>
        <input
          id="region"
          name={`${path}.region`}
          autoComplete="region"
          type="text"
          value={get(formValues, `${path}.region`)}
          onChange={handleChange}
          className={clsx(
            maybeAddErrorStyle(`${path}.region`, errors),
            "mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          )}
        />
      </div>
    </>
  );
};


export default AddressSubForm;
