import { HomeIcon } from '@heroicons/react/solid'
import { useHistory, useLocation } from "react-router-dom";
import findTenantById from "../helpers/tenants";

const pathToText = {
  "organisations": "Organisations",
  "users": "Users",
  "access-control": "Access Control",
  "product-configuration": "Product Configuration",
  "reports": "Reports",
  "new": "New Organisation",
  "edit": "Edit Details",
  "invite-user": "Create New User"
};

export const BreadCrumbs = ({ tenants }) => {
  const { push } = useHistory();
  const { pathname } = useLocation();
  const path = pathname.split("/");
  const breadCrumbs = [{id: path[1], text: pathToText[path[1]]}];

  const tenant = findTenantById(tenants, path[2]);
  if (tenant) breadCrumbs.push(...tenant.path);
  if (path[3]) breadCrumbs.push({id: path[3], text: pathToText[path[3]]});

  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol className="flex items-center space-x-4">
        <li>
          <div>
            <a href="#" className="text-gray-400 hover:text-gray-500">
              <HomeIcon className="flex-shrink-0 h-5 w-5" aria-hidden="true" onClick={() => push("/")} />
              <span className="sr-only">Home</span>
            </a>
          </div>
        </li>
        {breadCrumbs.map((crumb, i) => (
          <li key={crumb.id}>
            <div className="flex items-center">
              <svg
                className="flex-shrink-0 h-5 w-5 text-gray-300"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
                aria-hidden="true"
              >
                <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
              </svg>
              {path[3] !== crumb.id ? (
                <a
                  onClick={() => crumb.isTenant ?
                    push(`/organisations/${crumb.id}`) :
                    (crumb.id === "organisations" ? push(`/organisations/${tenants?.node?.id}`) : push(`/${crumb.id}`))
                  }
                  className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700 cursor-pointer"
                >
                  {crumb.text}
                </a>
              ) : (<p className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">{crumb.text}</p>)}
            </div>
          </li>
        ))}
      </ol>
    </nav>
  )
};

export default BreadCrumbs;
