import { XCircleIcon } from '@heroicons/react/solid'

export const ErrorMessage = ({text, errors}) => {
  return (
    <div className="rounded-md bg-red-50 p-4 mb-3">
      <div className="flex">
        <div className="flex-shrink-0">
          <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-red-800">{text}</h3>
          {errors.length > 0 && (
            <div className="mt-2 text-sm text-red-700">
              <ul className="list-disc pl-5 space-y-1">
                {errors.map(({ message }) => <li>{message}</li>)}
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  )
};
