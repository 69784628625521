import clsx from "clsx";
import React from "react";
import Loader from "./Loader";

const PageLoader = ({ fullPage = false }) => (
  <div className={clsx(fullPage ? "fixed inset-0 w-screen h-screen" : "w-full h-full", "flex items-center justify-center")}>
    <div className="text-center">
      <Loader className="inline-block" size="normal" />
      <p className="mt-2 text-sm text-gray-800">Loading…</p>
    </div>
  </div>
);

export default PageLoader;
