import get from "lodash.get";
import countries from "../../helpers/countries";
import InputSelect from "../../components/Input/InputSelect";
import { useHistory } from "react-router-dom";
import { maybeAddErrorStyle } from "../../helpers/maybeAddErrorStyle";
import clsx from "clsx";
import {ErrorMessage} from "../../components/ErrorMessage";
import InputPercentage from "../../components/Input/InputPercantage";
import AddressSubForm from "../../components/Organisation/AddressSubForm";

const OrganisationFormFields = ({ formValues, handleChange, tenants, tenant, isEditing, isUpdating, errors }) => {
  const { push } = useHistory();
  const isRootOrg = get(tenants, "0.id") === tenant?.id;

  return (
    <div className="shadow sm:rounded-md sm:overflow-hidden">
      <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <h3 className="text-lg font-medium leading-6 text-gray-900">Organisation Settings</h3>
            <p className="mt-1 text-sm text-gray-500">
              {isEditing ?
                `Editing details for ${tenant?.name}` :
                `Creating new organisation under parent: ${tenants?.find(t => t.id === formValues?.parentId)?.name || ""}`
              }
            </p>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
            <form action="#" method="POST">
              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6">
                  <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                    Name of organisation
                  </label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    autoComplete="name"
                    value={formValues.name}
                    onChange={handleChange}
                    required
                    className={clsx(
                      maybeAddErrorStyle("name", errors),
                      "mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    )}
                  />
                </div>
                <div className="col-span-6 sm:col-span-3">
                  <label htmlFor="umr" className="block text-sm font-medium text-gray-700">
                    UMR
                  </label>
                  <input
                    type="text"
                    name="umr"
                    id="umr"
                    autoComplete="umr"
                    value={formValues.umr}
                    onChange={handleChange}
                    className={clsx(
                      maybeAddErrorStyle("umr", errors),
                      "mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    )}
                  />
                </div>
                <div className="col-span-6 sm:col-span-3">
                  <label htmlFor="umr" className="block text-sm font-medium text-gray-700">
                    Commission
                  </label>
                  <InputPercentage
                    min={0}
                    max={100}
                    name="commission"
                    id="commission"
                    value={formValues.commission}
                    handleChange={handleChange}
                    className={clsx(
                      maybeAddErrorStyle("commission", errors),
                      "mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    )}
                  />
                </div>
                <div className="col-span-6 sm:col-span-3">
                  <label htmlFor="type" className="block text-sm font-medium text-gray-700">
                    Type of organisation
                  </label>
                  <select
                    id="type"
                    name="type"
                    autoComplete="type"
                    value={formValues.type}
                    onChange={handleChange}
                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  >
                    <option value="placing-broker">Placing Broker</option>
                    <option value="producing-broker">Producing Broker</option>
                  </select>
                </div>
                <div className="col-span-6 sm:col-span-3">
                  <InputSelect
                    options={tenants?.map(({ id, name }) => ({id, name}))}
                    label="Parent organisation"
                    value={formValues.parentId}
                    handleChange={handleChange}
                    name="parentId"
                    disabled={! isRootOrg || isEditing}
                  />
                </div>
                <AddressSubForm formValues={formValues} path="address" handleChange={handleChange} errors={errors} />
              </div>
            </form>
          </div>
        </div>
        {errors && <ErrorMessage text="Missing required fields" errors={errors} />}
      </div>
      <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
        {isEditing && (
          <button
            onClick={() => push("/organisations/"+tenant.id)}
            className="text-gray-700 bg-white mr-4 border border-gray-300 rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Cancel
          </button>
        )}
        <button
          type="submit"
          disabled={isUpdating}
          className="bg-indigo-600 disabled:opacity-50 disabled:cursor-not-allowed border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          {isEditing ? "Save" : "Create"}
        </button>
      </div>
    </div>
  );
};

export default OrganisationFormFields;
