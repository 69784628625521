import React, { useEffect } from 'react'
import get from "lodash.get";
import SideBar from "./SideBar";
import TopBar from "./TopBar";
import {useQuery} from "react-query";
import { useHistory } from "react-router-dom";
import {getTenants} from "../../api";
import PageLoader from "../PageLoader";
import Auth from '@aws-amplify/auth';
import { normaliseUser } from "../../helpers/users";

export const Layout = ({ children }) => {
  const { push } = useHistory();
  const authGuard = async () => {
    try {
      const amplifyUser = await Auth.currentAuthenticatedUser();
      const normalisedUser = normaliseUser(amplifyUser);
      window.localStorage.setItem("user", JSON.stringify(normalisedUser));
    } catch (err) {
      return push("/login")
    }
  };
  useEffect(() => authGuard(), []);

  const query = useQuery('tenants', getTenants);
  const tenants = get(query, "data.data");

  if (query.isLoading) return <PageLoader fullPage />;

  return (
    <div className="h-screen flex overflow-hidden bg-gray-100">
      <SideBar tenants={tenants} />
      <div className="flex flex-col w-0 flex-1 overflow-hidden">
        <TopBar tenants={tenants} />
        <main className="flex-1 relative overflow-y-auto focus:outline-none">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 py-6 h-full">
            {children}
          </div>
        </main>
      </div>
    </div>
  );
};

export default Layout;
