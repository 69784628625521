import React, { useState, useEffect } from "react";
import { useHistory, matchPath, useLocation } from "react-router-dom";
import set from "lodash.set";
import get from "lodash.get";
import omit from "lodash.omit";
import useQueryHooks from "../../helpers/useQueryHooks";
import PageLoader from "../PageLoader";
import {getTenantsFlat} from "../../api";
import {useQuery} from "react-query";
import { schema, OrganisationFormFields, additionalFormFields } from "../../helpers/tenantComponents";
import Ajv from "ajv";

const ajv = new Ajv({ allErrors: true, verbose: true, coerceTypes: true, useDefaults: true });

const initialFormvalues = (tenantId) => ({
  type: "placing-broker",
  address: {country: "United Kingdom"},
  parentId: tenantId,
  ...additionalFormFields
});

const OrganisationInnerForm = ({createTenant, updateTenant, tenant, tenants, isUpdating }) => {
  const { pathname } = useLocation();
  const { push } = useHistory();

  const isEditing = matchPath(pathname, {
    path: '/organisations/:tenantId/edit',
    exact: true,
    strict: false
  });

  const [formValues, setFormValues] = useState(isEditing ? omit(tenant, ["users", "id", "pk", "sk"]) : initialFormvalues(tenant.id));
  const [formErrors, setFormErrors] = useState();

  const handleChange = (...args) => {
    const nextFormValues = {...formValues};
    if (args.length === 1) {
      const [event] = args;
      console.log(event.target.value, event.target.name);
      const nextValue = event.target.value === "" ? undefined : event.target.value;
      set(nextFormValues, event.target.name, nextValue);
    }

    if (args.length === 2) {
      const [value, name] = args;
      console.log(value, name);
      const nextValue = value === "" ? undefined : value;
      console.log(nextValue, name);
      set(nextFormValues, name, nextValue);
    }

    setFormValues(nextFormValues);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const valid = ajv.validate(schema, formValues);
    console.log(valid, ajv.errors);
    if (! valid) return setFormErrors(ajv.errors);

    const submit = isEditing ? updateTenant : createTenant;
    const tenantId = isEditing ? tenant.id : undefined;

    const resp = await submit({tenantId, data: {...formValues, parentId: formValues.parentId}});
    push("/organisations/"+resp.data.id);
  };

  return (
      <form onSubmit={handleSubmit} >
        <OrganisationFormFields
          formValues={formValues}
          handleChange={handleChange}
          tenants={tenants}
          tenant={tenant}
          isEditing={isEditing}
          errors={formErrors}
          isUpdating={isUpdating}
        />
      </form>
  );
};

const OrganisationForm = () => {
  const { createTenant, updateTenant, tenant, isLoading, isUpdating } = useQueryHooks();
  const query = useQuery('tenantsFlat', getTenantsFlat);
  const tenants = get(query, "data.data");

  if (isLoading || query.isLoading) return <PageLoader/>;

  return <OrganisationInnerForm
    createTenant={createTenant}
    updateTenant={updateTenant}
    tenant={tenant}
    tenants={tenants}
    isUpdating={isUpdating}
  />
};

export default OrganisationForm;
