import React from "react";
import NavLinks from "./NavLinks";
import set from "lodash.set";
import { IdentificationIcon, OfficeBuildingIcon, UsersIcon} from "@heroicons/react/outline/index";
import { logo } from "../../helpers/tenantComponents";

const navItems = [
  { node: {id: "organisations", name: 'Organisations', href: '#', icon: OfficeBuildingIcon}, isStatic: true},
  { node: {id: "users", name: 'Users', href: '#', icon: UsersIcon}, isStatic: true},
  { node: {id: "access-control", name: 'Access Control', href: '#', icon: IdentificationIcon}, isStatic: true},
  // { node: {id: "product-configuration", name: 'Product Configuration', href: '#', icon: CogIcon}, isStatic: true},
  // { node: {id: "reports", name: 'Reports', href: '#', icon: ChartBarIcon}, isStatic: true},
];

const SideBar = ({ tenants }) => {
  const item = {node: {id: "button"}};
  set(navItems, "0.children", tenants ? [item, ...tenants.children] : [item]);

  return (
    <div className="hidden md:flex md:flex-shrink-0">
      <div className="flex flex-col w-64">
        <div className="flex flex-col h-0 flex-1">
          <div className="flex items-center h-16 flex-shrink-0 px-4 bg-gray-900">
            <img
              className="h-8 w-auto"
              src={logo}
              alt="Workflow"
            />
          </div>
          <div className="flex-1 flex flex-col overflow-y-auto">
            <nav className="flex-1 px-2 py-4 bg-gray-800 space-y-1">
              {navItems.map((item) => <NavLinks item={item} tenants={tenants} />)}
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideBar;
