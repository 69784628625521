import Auth from "@aws-amplify/auth";
import axios from "axios";
import get from "lodash.get";

export const fetch = axios.create({ baseURL: process.env.REACT_APP_API_URL });

const requestInterceptor = async (config) => {
  const amplifyUser = await Auth.currentAuthenticatedUser();
  const token = get(amplifyUser, "signInUserSession.idToken.jwtToken");

  return { ...config, headers: { ...config.headers, Authorization: token } };
};

fetch.interceptors.request.use(requestInterceptor);


/**
 * Roles
 */
export const getRoles = () => fetch({ method: "get", url: "/roles"});

/**
 * Users
 */
export const getUsers = () => fetch({ method: "get", url: "/users" });
export const createUser = ({ data }) => fetch({ method: "post", url: "/users", data });
export const updateUser = ({ sub, data }) => fetch({ method: "put", url: `/users/${sub}`, data });
export const resendInvite = ({ sub }) => fetch({ method: "put", url: `/users/${sub}`, data: {action: "RESEND"} });
export const getUser = ({ queryKey }) => {
  const [, { sub }] = queryKey;

  return fetch({ method: "get", url: `/users/${sub}`});
};


/**
 * Tenants
 */
export const getTenants = () => fetch({ method: "get", url: "/tenants" });

export const getTenantsFlat = () => fetch({ method: "get", url: "/tenants?flat=1" });

export const getTenant = ({ queryKey }) => {
  const [, { tenantId }] = queryKey;

  return fetch({ method: "get", url: `/tenants/${tenantId}?include=users`});
};

export const createTenant = ({ data }) => fetch({ method: "post", url: "/tenants", data });

export const updateTenant = ({ tenantId, data }) => fetch({ method: "put", url: `/tenants/${tenantId}`, data });
