import NumberFormat from 'react-number-format';

const InputPercentage = ({ value, name, title, handleChange, min, max, className }) => {
  return (
    <div>
      <label htmlFor={name} className="block text-sm font-medium text-gray-700">
        {title}
      </label>
      <div className="relative">
        <div className="absolute inset-y-0 right-0 flex items-center justify-center w-8">%</div>
        <NumberFormat
          name={name}
          id={name}
          value={value}
          onChange={handleChange}
          isAllowed={({ floatValue, value }) => value === "" || (floatValue <= max && floatValue >= min)}
          className={className}
        />
      </div>
    </div>
  )
};

export default InputPercentage;
