import get from "lodash.get";
import clsx from "clsx";
import InputSelect from "../../components/Input/InputSelect";
import { useHistory } from "react-router-dom";
import {InputRadio} from "../../components/Input/InputRadio";
import InputPercentage from "../../components/Input/InputPercantage";
import { maybeAddErrorStyle } from "../../helpers/maybeAddErrorStyle";
import {ErrorMessage} from "../../components/ErrorMessage";
import AddressSubForm from "../../components/Organisation/AddressSubForm";
import InputMultiSelect from "../../components/Input/InputMultiSelect";
import schema from "../chaucer/schema.json";

const isAuthorisedInAllUsStatesOptions = schema.definitions.yes_no.enum;

const usStates = schema.definitions.us_states.enum;

const securities = [
  {
    id: "security_1",
    name: "SECURITY 1",
    description: "1084 Chaucer Syndicates Limited on behalf of Syndicate 1084"
  },
  {
    id: "security_2",
    name: "SECURITY 2",
    description: "CIC Chaucer Insurance Company DAC"
  }
];


const OrganisationFormFields = ({ formValues, handleChange, tenants, tenant, isEditing, isUpdating, errors }) => {
  const { push } = useHistory();
  const isRootOrg = get(tenants, "0.id") === tenant?.id;

  return (
    <div className="space-y-6">
      <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <h3 className="text-lg font-medium leading-6 text-gray-900">General Information</h3>
            <p className="mt-1 text-sm text-gray-500">
              {isEditing ?
                `Editing details for ${tenant?.name}` :
                `Creating new organisation under parent: ${tenants?.find(t => t.id === formValues?.parentId)?.name || ""}`
              }
            </p>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
            <form action="#" method="POST">
              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6">
                  <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                    Name of organisation
                  </label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    autoComplete="name"
                    value={formValues.name}
                    onChange={handleChange}
                    required
                    className={clsx(
                      maybeAddErrorStyle("name", errors),
                      "mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    )}
                  />
                </div>
                <div className="col-span-6 sm:col-span-3">
                  <label htmlFor="type" className="block text-sm font-medium text-gray-700">
                    Type of organisation
                  </label>
                  <select
                    id="type"
                    name="type"
                    autoComplete="type"
                    value={formValues.type}
                    onChange={handleChange}
                    className={clsx(
                      maybeAddErrorStyle("type", errors),
                      "mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    )}
                  >
                    <option value="placing-broker">Placing Broker</option>
                    <option value="producing-broker">Producing Broker</option>
                  </select>
                </div>
                <div className="col-span-6 sm:col-span-3">
                  <InputSelect
                    options={tenants.map(({ id, name }) => ({id, name}))}
                    label="Parent organisation"
                    value={formValues.parentId || null}
                    handleChange={handleChange}
                    name="parentId"
                    disabled={! isRootOrg || isEditing}
                  />
                </div>
                <AddressSubForm formValues={formValues} path="address" handleChange={handleChange} errors={errors} />
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <h3 className="text-lg font-medium leading-6 text-gray-900">General Details</h3>
            <p className="mt-1 text-sm text-gray-500">Details applying to both products</p>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
            <form action="#" method="POST">
              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6 sm:col-span-3">
                  <label htmlFor="umr" className="block text-sm font-medium text-gray-700">
                    UMR
                  </label>
                  <input
                    type="text"
                    name="umr"
                    id="umr"
                    autoComplete="umr"
                    value={formValues.umr}
                    onChange={handleChange}
                    className={clsx(
                      maybeAddErrorStyle("umr", errors),
                      "mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    )}
                  />
                </div>
                <div className="col-span-6 sm:col-span-3">
                  <label htmlFor="broker_license" className="block text-sm font-medium text-gray-700">
                    Broker Licence Number
                  </label>
                  <input
                    type="text"
                    name="broker_license"
                    id="broker_license"
                    autoComplete="broker_license"
                    value={formValues.broker_license}
                    onChange={handleChange}
                    className={clsx(
                      maybeAddErrorStyle("broker_license", errors),
                      "mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    )}
                  />
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <label htmlFor="commission" className="block text-sm font-medium text-gray-700">
                    Commission
                  </label>
                  <InputPercentage
                    min={0}
                    max={100}
                    name="commission"
                    id="commission"
                    value={formValues.commission}
                    handleChange={handleChange}
                    className={clsx(
                      maybeAddErrorStyle("commission", errors),
                      "mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    )}
                  />
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <label htmlFor="broker_email" className="block text-sm font-medium text-gray-700">
                    Broker Email
                  </label>
                  <input
                    type="text"
                    name="broker_email"
                    id="broker_email"
                    autoComplete="broker_email"
                    value={formValues.broker_email}
                    onChange={handleChange}
                    className={clsx(
                      maybeAddErrorStyle("broker_email", errors),
                      "mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    )}
                  />
                </div>

                <div className="col-span-6">
                  <label htmlFor="broker_domicile" className="block text-sm font-medium text-gray-700">
                    Broker Allowed Domicile
                  </label>
                  <input
                    type="text"
                    name="broker_domicile"
                    id="broker_domicile"
                    autoComplete="broker_domicile"
                    value={formValues.broker_domicile}
                    onChange={handleChange}
                    className={clsx(
                      maybeAddErrorStyle("broker_domicile", errors),
                      "mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    )}
                  />
                </div>

                <div className="col-span-6">
                  <label htmlFor="claims_info" className="block text-sm font-medium text-gray-700">
                    Claims Info
                  </label>
                  <div className="mt-1">
                    <textarea
                      id="claims_info"
                      name="claims_info"
                      rows={3}
                      className={clsx(
                        maybeAddErrorStyle("claims_info", errors),
                        "shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"
                      )}
                      value={formValues.claims_info}
                      onChange={handleChange}
                    />
                  </div>
                  <p className="mt-2 text-sm text-gray-500">
                    Any information related to claims
                  </p>
                </div>
                <div className="col-span-6">
                  <InputRadio
                    options={securities}
                    label="Security"
                    value={formValues.security}
                    handleChange={handleChange}
                    name="security"
                  />
                </div>
                <div className="col-span-6">
                  <InputRadio
                    options={isAuthorisedInAllUsStatesOptions.map((i) => ({ id: i, name: i }))}
                    label="Authorised in all US States?"
                    value={formValues.is_authorised_in_all_us_states}
                    handleChange={handleChange}
                    name="is_authorised_in_all_us_states"
                  />
                </div>
                {formValues.is_authorised_in_all_us_states === "No" && (
                    <div className="col-span-6">
                      <InputMultiSelect
                          options={usStates}
                          label="Authorised in the following US States"
                          value={formValues.us_states}
                          onChange={handleChange}
                          name="us_states"
                      />
                    </div>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <h3 className="text-lg font-medium leading-6 text-gray-900">Annual Policy Details</h3>
            <p className="mt-1 text-sm text-gray-500">Details specific to the Annual Policy</p>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
            <form action="#" method="POST">
              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6 sm:col-span-5">
                  <label htmlFor="binding_authority" className="block text-sm font-medium text-gray-700">
                    Binding Authority Number
                  </label>
                  <input
                    type="text"
                    name="annual.binding_authority"
                    id="binding_authority"
                    autoComplete="binding_authority"
                    value={formValues?.annual?.binding_authority}
                    onChange={handleChange}
                    className={clsx(
                      maybeAddErrorStyle("annual.binding_authority", errors),
                      "mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    )}
                  />
                </div>
                <div className="col-span-6">
                  <h4 className="text-lg leading-6 font-medium text-gray-900 mb-3">Surveyor Details</h4>
                  <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                    Name
                  </label>
                  <input
                    type="text"
                    name="annual.surveyor.name"
                    id="name"
                    autoComplete="name"
                    value={formValues?.annual?.surveyor?.name}
                    onChange={handleChange}
                    className={clsx(
                      maybeAddErrorStyle("annual.surveyor.name", errors),
                      "mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    )}
                  />
                </div>
                <AddressSubForm formValues={formValues} path="annual.surveyor.address" handleChange={handleChange} errors={errors} />
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <h3 className="text-lg font-medium leading-6 text-gray-900">Single Policy Details</h3>
            <p className="mt-1 text-sm text-gray-500">Details specific to the Single Policy</p>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
            <form action="#" method="POST">
              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6 sm:col-span-5">
                  <label htmlFor="binding_authority" className="block text-sm font-medium text-gray-700">
                    Binding Authority Number
                  </label>
                  <input
                    type="text"
                    name="single.binding_authority"
                    id="binding_authority"
                    autoComplete="binding_authority"
                    value={formValues?.single?.binding_authority}
                    onChange={handleChange}
                    className={clsx(
                      maybeAddErrorStyle("single.binding_authority", errors),
                      "mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    )}
                  />
                </div>
                <div className="col-span-6">
                  <h4 className="text-lg leading-6 font-medium text-gray-900 mb-3">Surveyor Details</h4>
                  <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                    Name
                  </label>
                  <input
                    type="text"
                    name="single.surveyor.name"
                    id="name"
                    autoComplete="name"
                    value={formValues?.single?.surveyor?.name}
                    onChange={handleChange}
                    className={clsx(
                      maybeAddErrorStyle("single.surveyor.name", errors),
                      "mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    )}
                  />
                </div>
                <AddressSubForm formValues={formValues} path="single.surveyor.address" handleChange={handleChange} errors={errors} />
                <div className="col-span-6">
                  <h4 className="text-lg leading-6 font-medium text-gray-900 -mb-2">Load and Discount</h4>
                </div>
                <div className="col-span-2">
                  <InputPercentage
                    title="FCL"
                    name="single.surveyor.fcl"
                    min={-100}
                    max={100}
                    value={formValues?.single?.surveyor?.fcl}
                    handleChange={handleChange}
                    className={clsx(
                      maybeAddErrorStyle("single.surveyor.fcl", errors),
                      "mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    )}
                  />
                </div>
                <div className="col-span-2">
                  <InputPercentage
                    title="Air"
                    name="single.surveyor.air"
                    min={-100}
                    max={100}
                    value={formValues?.single?.surveyor?.air}
                    handleChange={handleChange}
                    className={clsx(
                      maybeAddErrorStyle("single.surveyor.air", errors),
                      "mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    )}
                  />
                </div>
                <div className="col-span-2">
                  <InputPercentage
                    title="Land Only"
                    name="single.surveyor.land_only"
                    min={-100}
                    max={100}
                    value={formValues?.single?.surveyor?.land_only}
                    handleChange={handleChange}
                    className={clsx(
                      maybeAddErrorStyle("single.surveyor.land_only", errors),
                      "mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    )}
                  />
                </div>
                <div className="col-span-2">
                  <InputPercentage
                    title="ICC C"
                    name="single.surveyor.icc_c"
                    min={-100}
                    max={100}
                    value={formValues?.single?.surveyor?.icc_c}
                    handleChange={handleChange}
                    className={clsx(
                      maybeAddErrorStyle("single.surveyor.icc_c", errors),
                      "mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    )}
                  />
                </div>
                <div className="col-span-2">
                  <InputPercentage
                    title="Own Vehicle"
                    name="single.surveyor.own_vehicle"
                    min={-100}
                    max={100}
                    value={formValues?.single?.surveyor?.own_vehicle}
                    handleChange={handleChange}
                    className={clsx(
                      maybeAddErrorStyle("single.surveyor.own_vehicle", errors),
                      "mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    )}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      {errors && <ErrorMessage text="Missing required fields" errors={errors} />}
      <div className="flex justify-end pb-10">
        {isEditing && (
          <button
            onClick={() => push("/organisations/"+tenant.id)}
            className="text-gray-700 bg-white mr-4 border border-gray-300 rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Cancel
          </button>
        )}
        <button
          type="submit"
          disabled={isUpdating}
          className="bg-indigo-600 disabled:opacity-50 disabled:cursor-not-allowed border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          {isEditing ? "Save" : "Create"}
        </button>
      </div>
    </div>
  );
};

export default OrganisationFormFields;
