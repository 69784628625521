import React, { useEffect } from "react";
import * as api from "../api";
import get from "lodash.get";
import { useLocation, useParams, matchPath } from "react-router-dom";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {getTenants, getRoles} from "../api";

const useQueryHooks = () => {
  const queryClient = useQueryClient();
  const { pathname } = useLocation();
  const { tenantId, sub } = useParams();

  const query = useQuery('tenants', getTenants);
  const tenants = get(query, "data.data");
  const isRootOrg = matchPath(pathname, {path: '/organisations', exact: true, strict: false});

  const tenantQuery = useQuery(["tenant", { tenantId }], api.getTenant, {enabled: Boolean(tenantId)});
  const tenant = get(tenantQuery, "data.data");

  const userQuery = useQuery(["user", { sub }], api.getUser, {enabled: Boolean(sub)});
  const user = get(userQuery, "data.data");

  const roleQuery = useQuery('roles', getRoles);
  const roles = get(roleQuery, "data.data");

  const { mutateAsync: createTenant, ...createTenantQuery } = useMutation(api.createTenant, {
    onSuccess: () => {
      queryClient.invalidateQueries("tenants");
    },
  });

  const { mutateAsync: updateTenant, ...updateTenantQuery } = useMutation(api.updateTenant, {
    onSuccess: () => {
      queryClient.invalidateQueries("tenant");
    },
  });

  const { mutateAsync: createUser, ...createUserQuery } = useMutation(api.createUser, {
    onSuccess: () => {
      queryClient.invalidateQueries("users");
    },
  });

  const { mutateAsync: updateUser, ...updateUserQuery } = useMutation(api.updateUser, {
    onSuccess: () => {
      queryClient.invalidateQueries("user");
    },
  });

  const isLoading = tenantQuery.isLoading || userQuery.isLoading || roleQuery.isLoading;

  const isUpdating = createTenantQuery.isLoading || createUserQuery.isLoading || updateTenantQuery.isLoading || updateUserQuery.isLoading;

  return {
    tenantId,
    tenant,
    tenants,
    roles,
    createTenant,
    user,
    updateTenant,
    createUser,
    updateUser,
    isLoading,
    isUpdating
  }
};

export default useQueryHooks;
