import React, { useEffect } from "react";
import get from "lodash.get";
import UserCard from "./UserCard";
import PageLoader from "../PageLoader";
import {useQuery} from "react-query";
import * as api from "../../api";

const UserPage = () => {
  const userQuery = useQuery("users", api.getUsers);
  const users = get(userQuery, "data.data");
  if (userQuery.isLoading) return <PageLoader/>;

  return (
    <div className="space-y-6">
      <UserCard isAllUsers users={users} />
    </div>
  );
};

export default UserPage;
