import React, { useState, useEffect } from "react";
import { useHistory, matchPath, useLocation } from "react-router-dom";
import clsx from "clsx";
import set from "lodash.set";
import get from "lodash.get";
import useQueryHooks from "../../helpers/useQueryHooks";
import PageLoader from "../PageLoader";
import InputSelect from "../Input/InputSelect";
import {useQuery} from "react-query";
import {getTenantsFlat} from "../../api";
import {InputRadio} from "../Input/InputRadio";
import {parseParams} from "../../helpers/parseParams";
import {ErrorMessage} from "../ErrorMessage";
import { externalIdentityProvider } from "../../helpers/tenantComponents";
import ToggleEnabledUserModal from "../Modal/ToggleEnabledUserModal";

const internalIdentityProvider =   {
  id: "internal",
  name: "Username and Password",
  description: "Users can sign up with a username and password combination"
};
const identityProviders = [internalIdentityProvider, externalIdentityProvider];

const UserInnerForm = ({createUser, updateUser, user, roles, tenants, isUpdating}) => {
  const [isToggleEnabledModalOpen, setIsToggleEnabledModalOpen] = useState(false);
  const requiredFields = ["email", "firstName", "lastName", "role", "tenantId", "identityProvider"];
  const { pathname } = useLocation();
  const { push, location } = useHistory();
  const { parentId } = parseParams(location.search);
  const parentTenant = tenants?.find(t => t.id === parentId);

  const isEditing = matchPath(pathname, {
    path: '/users/:sub/edit',
    exact: true,
    strict: false
  });

  const [error, setError] = useState(false);
  const [formValues, setFormValues] = useState({});

  useEffect(() => {
    if (isEditing) {
      setFormValues({
        ...user,
        ...formValues
      });
    } else if (parentId) {
      setFormValues({
        tenantId: parentId,
        role: "admin",
        identityProvider: internalIdentityProvider.id,
        ...formValues
      });
    } else if(tenants?.[0]?.id) {
      setFormValues({
        tenantId: tenants?.[0]?.id,
        role: "admin",
        identityProvider: internalIdentityProvider.id,
        ...formValues
      });
    }
  }, [tenants?.[0]?.id, parentId, user]);

  const handleChange = (...args) => {
    const nextFormValues = {...formValues};
    if (args.length === 1) {
      const [event] = args;
      set(nextFormValues, event.target.name, event.target.value);
    }

    if (args.length === 2) {
      const [value, name] = args;
      set(nextFormValues, name, value);
    }

    setFormValues(nextFormValues);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const submit = isEditing ? updateUser : createUser;
    const sub = isEditing ? user.sub : undefined;

    try {
      await submit({sub, data: formValues});
      push("/users");
    } catch (err) {
      setError(err.response.data.message);
    }
  };

  const handleClickToggleEnabledStateOnUser = (e) => {
    e.preventDefault();
    setIsToggleEnabledModalOpen(true);
  };

  const handleToggleEnabled = async (e) => {
    e.preventDefault();
    setIsToggleEnabledModalOpen(false);
    try {
      await updateUser({sub: user.sub, data: { username: user.username, enabled: !user.enabled, action: "TOGGLE_ENABLED" }});
    } catch (err) {
      setError(err.response.data.message);
    }
  };

  return (
    <>
      {user && <ToggleEnabledUserModal user={user} toggleEnabled={handleToggleEnabled} open={isToggleEnabledModalOpen} setOpen={setIsToggleEnabledModalOpen} />}
      <form onSubmit={handleSubmit}>
        <div className="shadow">
          <div className="bg-white py-6 px-4 space-y-6 sm:p-6 sm:rounded-md">
            <div className="md:grid md:grid-cols-3 md:gap-6">
              <div className="md:col-span-1">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  {isEditing ? "Edit user details" : (parentTenant ? "Invite new user to " + parentTenant.name : "Invite new user")}
                </h3>
                <p className="mt-1 text-sm text-gray-500">{! isEditing && "Create new user and send invitation email"}</p>
              </div>
              <div className="mt-5 md:mt-0 md:col-span-2">
                <form action="#" method="POST">
                  <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-6 sm:col-span-3">
                      <label htmlFor="firstName" className="block text-sm font-medium text-gray-700">
                        First name
                      </label>
                      <input
                        type="text"
                        name="firstName"
                        id="firstName"
                        value={formValues.firstName}
                        onChange={handleChange}
                        autoComplete="given-name"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                    <div className="col-span-6 sm:col-span-3">
                      <label htmlFor="last_name" className="block text-sm font-medium text-gray-700">
                        Last name
                      </label>
                      <input
                        type="text"
                        name="lastName"
                        id="lastName"
                        value={formValues.lastName}
                        onChange={handleChange}
                        autoComplete="family-name"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                    <div className="col-span-6 sm:col-span-4">
                      <label htmlFor="email_address" className="block text-sm font-medium text-gray-700">
                        Email address
                      </label>
                      <input
                        type="text"
                        name="email"
                        id="email"
                        value={formValues.email}
                        onChange={handleChange}
                        autoComplete="email"
                        disabled={Boolean(isEditing)}
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md disabled:opacity-50 disabled:cursor-not-allowed"
                      />
                    </div>
                    <div className="col-span-6 sm:col-span-3">
                      <InputSelect
                        options={tenants.map(({ id, name }) => ({id, name}))}
                        label="Organisation membership"
                        value={formValues.tenantId}
                        handleChange={handleChange}
                        name="tenantId"
                        disabled={Boolean(parentId) || Boolean(isEditing)}
                      />
                    </div>
                    <div className="col-span-6 sm:col-span-3">
                      <InputSelect options={roles.map(({name}) => ({name, id: name}))} value={formValues.role} label="Role" handleChange={handleChange} name="role" />
                    </div>
                    {externalIdentityProvider && (
                      <div className="col-span-6">
                        <InputRadio
                          options={identityProviders}
                          label="Sign Up Method"
                          value={formValues.identityProvider}
                          handleChange={handleChange}
                          name="identityProvider"
                          disabled={Boolean(isEditing)}
                        />
                      </div>
                    )}
                  </div>
                </form>
              </div>
            </div>
            {error && <ErrorMessage text={error}/>}
          </div>
          <div className="px-4 py-3 bg-gray-50 text-right sm:px-6 sm:rounded-md">
            {isEditing && (
                <button
                    onClick={handleClickToggleEnabledStateOnUser}
                    disabled={isUpdating}
                    className={clsx(user.enabled ? "bg-red-600 hover:bg-red-700 focus:ring-red-500" : "bg-green-600 hover:bg-green-700 focus:ring-green-500", "float-left disabled:opacity-50 disabled:cursor-not-allowed border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2")}
                >
                  {user.enabled ? "Disable" : "Enable"}
                </button>
            )}
            <button
              onClick={() => push("/users")}
              className="text-gray-700 bg-white mr-4 border border-gray-300 rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={requiredFields.some(f => ! formValues[f]) || isUpdating}
              className="bg-indigo-600 disabled:opacity-50 disabled:cursor-not-allowed border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              {isEditing ? "Update" : "Send invite"}
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

const UserForm = () => {
  const { createUser, updateUser, user, roles, isLoading, isUpdating } = useQueryHooks();
  const query = useQuery('tenantsFlat', getTenantsFlat);
  const tenants = get(query, "data.data");

  if (isLoading || query.isLoading) return <PageLoader/>;
  return <UserInnerForm
    createUser={createUser}
    updateUser={updateUser}
    user={user}
    tenants={tenants}
    roles={roles}
    isUpdating={isUpdating}
  />
};

export default UserForm;
