import clsx from "clsx";
import React, { useState, useEffect } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { Disclosure } from "@headlessui/react";
import {PlusCircleIcon, PlusIcon} from "@heroicons/react/solid/index";

const NavLinks = ({ item, tenants, level = 0 }) => {
  const { push } = useHistory();
  const { pathname } = useLocation();
  const [expanded, setExpanded] = useState(false);
  const path = pathname.split("/");
  const id = item?.node?.id;
  let currentSelectionId = path[2] || path[1];
  let baseUrl = item.isStatic ? `/${id}` : `/organisations/${id}`;
  if (id === "organisations") {
    if (path[2] === tenants?.node?.id) currentSelectionId = "organisations";
    baseUrl = `/organisations/${tenants?.node?.id}`;
  }
  let name = item?.node?.name;

  if (level === 1 && name?.length > 17) name = item?.node?.name?.substr(0,17)+"...";
  if (level === 2 && name?.length > 12) name = item?.node?.name?.substr(0,12)+"...";
  if (level === 3 && name?.length > 10) name = item?.node?.name?.substr(0,10)+"...";

  if (id === "button") {
    return (
      <a
        className='mt-2 mb-3 text-gray-300 bg-indigo-600 hover:bg-indigo-700 group flex items-center text-sm font-medium rounded-md cursor-pointer'
      >
        <div className="inline-flex items-center px2 py-2 w-full" onClick={() => push(`/organisations/${tenants?.node?.id}/new`)}>
          <PlusIcon className={'text-gray-300 group-hover:text-gray-300 mr-1 ml-2 h-6 w-6'} aria-hidden="true"/>
          <p>New organisation</p>
        </div>
      </a>
    )
  }

  return (
    (!item.children || item?.children?.length < 1) ? (
      <a
        className={clsx(
          (id === currentSelectionId) ? 'bg-gray-700 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
          'group flex items-center text-sm font-medium rounded-md cursor-pointer',
          (level !== 0) ? 'ml-7' : ''
        )}
      >
        <div className="inline-flex px-2 py-2  w-full" onClick={() => push(baseUrl)}>
          {(level === 0) &&
          <item.node.icon className={'text-gray-300 group-hover:text-gray-300 mr-3 h-6 w-6'} aria-hidden="true"/>}
          {name}
        </div>
        {(! item.isStatic) && (
          <PlusCircleIcon
            className="h-6 w-6 mr-2 opacity-0 group-hover:opacity-100 text-white hover:text-gray-300"
            onClick={() => push(baseUrl + "/new")}
          />
        )}
      </a>
    ) : (
      <Disclosure as="div" key={id} className={`space-y-1 ${(level !== 0) ? ' ml-7' : ''}`}>
        {() => (
          <>
            <Disclosure.Button className="w-full">
              <div
                className={clsx(
                    (id === currentSelectionId) ? 'bg-gray-700 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                  'group flex items-center text-sm font-medium rounded-md focus:outline-none'
                )}
              >
                <div className="inline-flex py-2 w-full">
                  <svg
                    className={clsx(
                      expanded ? 'text-gray-400 rotate-90' : 'text-gray-300',
                      'h-6 w-6 mr-1 ml-2 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150 rounded-md hover:bg-gray-500'
                    )}
                    viewBox="0 0 20 20"
                    aria-hidden="true"
                    onClick={() => setExpanded(! expanded)}
                  >
                    <path d="M6 6L14 10L6 14V6Z" fill="currentColor"/>
                  </svg>
                  <div
                    className="w-full inline-flex items-center"
                    onClick={() => {
                      setExpanded((currentSelectionId !== id && expanded) ? true : ! expanded);
                      push(baseUrl)
                    }}
                  >
                    {(level === 0) &&
                    <item.node.icon className="text-gray-300 group-hover:text-gray-300 mr-3 h-6 w-6" aria-hidden="true"/>}
                    {name}
                  </div>
                </div>
                {(! item.isStatic) && (
                  <PlusCircleIcon
                    className="h-5 w-5 ml-auto mr-2 opacity-0 group-hover:opacity-100 text-white"
                    onClick={() => push(baseUrl + "/new")}
                  />
                )}
              </div>
            </Disclosure.Button>
            {expanded && (
              <Disclosure.Panel static className="space-y-1">
                {item.children.map((subItem) => (
                  <NavLinks item={subItem} level={level + 1} tenants={tenants} expandParent={setExpanded} />
                ))}
              </Disclosure.Panel>
            )}
          </>
        )}
      </Disclosure>
    )
  );
};

export default NavLinks;
