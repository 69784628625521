import { useState, useEffect, useRef } from 'react';

import clsx from 'clsx';
import { useCombobox, useMultipleSelection } from 'downshift';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid'

const sizes = {
  normal: 'min-h-11',
  small: 'min-h-8',
};
const styles = {
  menuInner: 'overflow-y-auto max-h-96',
  menuItem: 'py-2 px-3 cursor-pointer text-sm font-normal',
  menuOuter:
      'border-gray-300 border rounded absolute inset-x-0 top-0 z-20 bg-white text-gray-900 mt-11  shadow overflow-hidden',
};

const useDownshiftStyle = (anchorRef, ref) => {
  const [style, setStyle] = useState(null);

  useEffect(() => {
    const element = ref?.current;
    const anchorElement = anchorRef?.current;

    if (element && anchorElement) {
      const anchor = anchorElement.getBoundingClientRect();
      const bound = element.getBoundingClientRect();

      // if overflowing
      if (anchor.y + anchor.height > window.innerHeight - 64) {
        const height = bound.bottom - bound.y + 8; // get element height
        const style = { marginTop: -height };
        setStyle({ ...style });
      }
    }
  }, [ref]);

  return style;
};

export const MenuContainer = ({ anchorRef, children }) => {
  const ref = useRef(null);
  const style = useDownshiftStyle(anchorRef, ref);

  return (
      <div className={styles.menuOuter} ref={ref} style={style}>
        {children}
      </div>
  );
};

const InputMultiSelect = ({
  className,
  descriptionText,
  errors,
  isReadOnly,
  isSearchable,
  label,
  onChange,
  options,
  name,
  size,
  value = [],
  ...props
}) => {
  const [inputValue, setInputValue] = useState('');
  const { getSelectedItemProps, getDropdownProps, addSelectedItem, removeSelectedItem, selectedItems } =
    useMultipleSelection({
      initialSelectedItems: Array.isArray(value) ? value : typeof value === 'string' ? [value] : [],
    });
  const getFilteredItems = (items) =>
    items.filter(
      (item) =>
        selectedItems.indexOf(item) < 0 &&
        String(item ?? '')
          .toLowerCase()
          .includes(String(inputValue ?? '').toLowerCase())
    );
  const inputRef = useRef(null);

  const {
    isOpen,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
    selectItem,
  } = useCombobox({
    value,
    items: getFilteredItems(options),
    onStateChange: ({ inputValue, type, selectedItem }) => {
      switch (type) {
        case useCombobox.stateChangeTypes.InputChange:
          setInputValue(inputValue);
          break;
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
        case useCombobox.stateChangeTypes.InputBlur:
          if (selectedItem) {
            setInputValue('');
            addSelectedItem(selectedItem);
            selectItem(null);
          }

          break;
        default:
          break;
      }
    },
  });

  useEffect(() => {
    onChange(selectedItems, name);
  }, [selectedItems]);

  const isFull = getFilteredItems(options).length === 0;

  return (
    <div
      className={className}
      name={name}
      errors={errors}
      {...props}
    >
      <label htmlFor={name} className="block text-sm font-medium text-gray-700">
        {label}
      </label>
      {isReadOnly && (!value || !value.length) && (
        <div
          {...props}
          aria-describedby={descriptionText ? `${name}Description` : undefined}
          name={name}
          value={''}
        />
      )}

      {isReadOnly && value && value.length && (
        <div {...getMenuProps()}>
          <ul className={styles.menuInner}>
            {value.map((item, index) => (
              <li className={clsx(styles.menuItem, 'cursor-not-allowed')} key={`${item}${index}`}>
                {item}
              </li>
            ))}
          </ul>
        </div>
      )}

      {!isReadOnly && (
        <div className={clsx('relative')}>
          <div
            className={clsx(
              'flex justify-between cursor-pointer border rounded-md border-gray-300 py-2 px-3 pb-0',
              sizes[size]
            )}
            {...getToggleButtonProps()}
          >
            <div className="flex" ref={inputRef} style={{ flexWrap: "wrap" }}>
              {selectedItems.map((selectedItem, index) => (
                <div key={`selected-item-${index}`} className="bg-gray-200 rounded-md bg-rounded py-1 -my-1 pl-3 pr-2 mr-1 space-x-1 text-sm font-normal mb-2">
                  <span {...getSelectedItemProps({ selectedItem, index })} title={selectedItem}>
                    {selectedItem}
                  </span>
                  <span
                    className="cursor-pointer hover:bg-gray-400 rounded-full px-1 "
                    onClick={(ev) => {
                      ev.stopPropagation();
                      removeSelectedItem(selectedItem);
                    }}
                  >
                    &#10005;
                  </span>
                </div>
              ))}
              {selectedItems.length === 0 && (
                <label className="text-sm font-normal text-gray-700 mb-2" {...getLabelProps()}>
                  Select
                </label>
              )}
            </div>
            <div className="flex" {...getComboboxProps()}>
              <input className="hidden" {...getInputProps(getDropdownProps({ preventKeyAction: isOpen }))} />
              {!isOpen && <ChevronDownIcon name="chevron-down" className="ml-2 fill-current text-gray-800 self-center w-5" />}

              {isOpen && <ChevronUpIcon name="chevron-up" className="ml-2 fill-current text-blue-700 self-center w-5" />}
            </div>
          </div>
          <div {...getMenuProps()}>
            {isOpen && (
              <MenuContainer anchorRef={inputRef}>
                {isSearchable && (
                  <input autoFocus className="border-b border-gray-100 px-3 py-2 text-gray-900 w-full focus:outline-none" placeholder="Search..." {...getInputProps()} />
                )}
                <ul className={styles.menuInner}>
                  {getFilteredItems(options).map((item, index) => (
                    <li
                      className={clsx(styles.menuItem, index === highlightedIndex && 'bg-gray-100')}
                      key={`${item}${index}`}
                      {...getItemProps({ item, index })}
                    >
                      {item}
                    </li>
                  ))}
                  {isFull && <li className={clsx(styles.menuItem, 'italic opacity-60')}>No options left</li>}
                </ul>
              </MenuContainer>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

InputMultiSelect.defaultProps = {
  'aria-labelledby': undefined,
  className: undefined,
  descriptionText: undefined,
  formatter: undefined,
  id: undefined,
  initialSelectedItem: undefined,
  isReadOnly: undefined,
  isSearchable: true,
  itemFormatter: undefined,
  labelText: undefined,
  name: undefined,
  onChange: undefined,
  onSelect: undefined,
  options: undefined,
  size: 'normal',
};

export default InputMultiSelect;
