import OrganisationFormFields_base from "../schema/base/OrganisationFormFields";
import OrganisationFormFields_chaucer from "../schema/chaucer/OrganisationFormFields";
import OrganisationFormFields_chaucer_uk from "../schema/chaucer-uk/OrganisationFormFields";
import logo_convex from '../assets/logo.png';
import logo_colour_convex from '../assets/logo-colour.png';
import logo_chaucer from '../assets/chaucer-logo.svg';
import logo_chaucer_uk from '../assets/chaucer-uk-logo.svg';
import logo_sure from '../assets/sure_logo.png';
import logo_edbroking from '../assets/edbroking_logo.svg';
import logo_digiprop_colour from '../assets/digiprop-colour.png';
import logo_digiprop from '../assets/digiprop-logo.png';
import logo_artificial_colour from '../assets/artificial-logo.svg';
import logo_artificial from '../assets/artificial-logo-white.svg';

const clientRef = process.env.REACT_APP_CLIENT_REF;
const components = {
  convex: {
    OrganisationFormFields: OrganisationFormFields_base,
    additionalFormFields: {},
    logo: logo_convex,
    logo_colour: logo_colour_convex,
    schema: require("../schema/base/schema"),
    brokerCountries: ["United States", "United Kingdom"],
    externalIdentityProvider: {
      id: `jumpcloud-${process.env.REACT_APP_CLIENT_REF}-${process.env.REACT_APP_STAGE}`,
      name: "JumpCloud Directory Platform",
      displayName: "JumpCloud",
      description: "The user can sign up with their JumpCloud account"
    },
    rolesNotAuthorised: [],
  },
  chaucer: {
    OrganisationFormFields: OrganisationFormFields_chaucer,
    additionalFormFields: {single: {surveyor: {address: {}}}, annual: {surveyor: {address: {}}}},
    logo: logo_chaucer,
    logo_colour: logo_chaucer,
    schema: require("../schema/chaucer/schema"),
    brokerCountries: ["United States", "United Kingdom"],
    externalIdentityProvider: {
      id: `azure-${process.env.REACT_APP_CLIENT_REF}-${process.env.REACT_APP_STAGE}`,
      name: "Azure Active Directory",
      displayName: "Azure AD",
      description: "The user can sign up with their Azure Active Directory account"
    },
    rolesNotAuthorised: [],
  },
  "chaucer-uk": {
    OrganisationFormFields: OrganisationFormFields_chaucer_uk,
    additionalFormFields: {single: {surveyor: {address: {}}}, annual: {surveyor: {address: {}}}, security: "security_2"},
    logo: logo_chaucer_uk,
    logo_colour: logo_chaucer_uk,
    schema: require("../schema/chaucer-uk/schema"),
    brokerCountries: ["United States", "United Kingdom"],
    externalIdentityProvider: {
      id: `azure-${process.env.REACT_APP_CLIENT_REF}-${process.env.REACT_APP_STAGE}`,
      name: "Azure Active Directory",
      displayName: "Azure AD",
      description: "The user can sign up with their Azure Active Directory account"
    },
    rolesNotAuthorised: [],
  },
  sure: {
    OrganisationFormFields: OrganisationFormFields_base,
    additionalFormFields: {},
    logo: logo_sure,
    logo_colour: logo_sure,
    schema: require("../schema/base/schema"),
    brokerCountries: ["United States", "United Kingdom"],
    externalIdentityProvider: null,
    rolesNotAuthorised: ["hospitals-and-clinics","surgeons"],
  },
  edbroking: {
    OrganisationFormFields: OrganisationFormFields_base,
    additionalFormFields: {},
    logo: logo_edbroking,
    logo_colour: logo_edbroking,
    brokerCountries: ["United States", "United Kingdom"],
    schema: require("../schema/base/schema"),
    externalIdentityProvider: null,
    rolesNotAuthorised: [],
  },
  lockton: {
    OrganisationFormFields: OrganisationFormFields_base,
    additionalFormFields: {},
    logo: logo_artificial,
    logo_colour: logo_artificial_colour,
    brokerCountries: ["United States", "United Kingdom"],
    schema: require("../schema/base/schema"),
    externalIdentityProvider: null,
    rolesNotAuthorised: [],
  },
  bms: {
    OrganisationFormFields: OrganisationFormFields_base,
    additionalFormFields: {},
    logo: logo_artificial,
    logo_colour: logo_artificial_colour,
    brokerCountries: ["United States", "United Kingdom"],
    schema: require("../schema/base/schema"),
    externalIdentityProvider: null,
    rolesNotAuthorised: [],
  },
  artificial: {
    OrganisationFormFields: OrganisationFormFields_base,
    additionalFormFields: {},
    logo: logo_artificial,
    logo_colour: logo_artificial_colour,
    brokerCountries: ["United States", "United Kingdom"],
    schema: require("../schema/base/schema"),
    externalIdentityProvider: null,
    rolesNotAuthorised: [],
  },
  digiprop: {
    OrganisationFormFields: OrganisationFormFields_base,
    additionalFormFields: {},
    logo: logo_digiprop,
    logo_colour: logo_digiprop_colour,
    brokerCountries: ["United States", "United Kingdom"],
    schema: require("../schema/base/schema"),
    externalIdentityProvider: null,
    rolesNotAuthorised: [],
  }
};

export const OrganisationFormFields = components[clientRef].OrganisationFormFields;
export const additionalFormFields = components[clientRef].additionalFormFields;
export const logo = components[clientRef].logo;
export const logo_colour = components[clientRef].logo_colour;
export const schema = components[clientRef].schema;
export const externalIdentityProvider = components[clientRef].externalIdentityProvider;
export const brokerCountries = components[clientRef].brokerCountries;
export const rolesNotAuthorised = components[clientRef].rolesNotAuthorised;