import React, { Fragment } from "react";
import Auth from '@aws-amplify/auth';
import { useHistory } from "react-router-dom";
import { Menu, Transition } from '@headlessui/react'
import {BellIcon, MenuAlt2Icon} from '@heroicons/react/outline'
import clsx from "clsx";
import Breadcrumbs from "../Breadcrumbs";
import Avatar from "../Avatar";
import {getInitials} from "../../helpers/users";

const TopBar = ({ tenants }) => {
  const { push } = useHistory();
  const userValue = window.localStorage.getItem("user");
  const user = JSON.parse(userValue === "undefined" ? null : userValue);
  const userNavigation = [{ name: 'Sign out', href: '#' }];

  const handleLogout = async () => {
    window.localStorage.removeItem("user");
    await Auth.signOut();
    push("/login");
  };

  return (
    <div className="relative z-10 flex-shrink-0 flex h-16 bg-white shadow">
      <div className="flex-1 px-4 flex justify-between">
        <div className="flex-1 flex">
          <Breadcrumbs tenants={tenants} />
        </div>
        <div className="ml-4 flex items-center md:ml-6">
          <Menu as="div" className="ml-3 relative">
            {({ open }) => (
              <>
                <div>
                  <Menu.Button className="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    <span className="sr-only">Open user menu</span>
                    <Avatar initials={getInitials(user)} />
                  </Menu.Button>
                </div>
                <Transition
                  show={open}
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items
                    static
                    className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                  >
                    {userNavigation.map((item) => (
                      <Menu.Item key={item.name}>
                        {({ active }) => (
                          <a
                            href={item.href}
                            className={clsx(
                              active ? 'bg-gray-100' : '',
                              'block px-4 py-2 text-sm text-gray-700'
                            )}
                            onClick={handleLogout}
                          >
                            {item.name}
                          </a>
                        )}
                      </Menu.Item>
                    ))}
                  </Menu.Items>
                </Transition>
              </>
            )}
          </Menu>
        </div>
      </div>
    </div>
  );
};

export default TopBar;
