import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { QueryClientProvider, QueryClient } from "react-query";
import Login from "./Login";
import UserActivation from "./UserActivation";
import Layout from "./Layout";
import OrganisationPage from "./Organisation/OrganisationPage";
import OrganisationForm from "./Organisation/OrganisationForm";
import UserPage from "./User/UserPage";
import UserForm from "./User/UserForm";
import {Redirect} from "react-router";

const capitalize = (s: any) => s && s[0].toUpperCase() + s.slice(1);

const App = () => {
  useEffect(() => {
    document.title = capitalize(process.env.REACT_APP_CLIENT_REF) + " Console";
  }, []);

  return (
    <Router>
      <QueryClientProvider client={new QueryClient()}>
        <Switch>
          <Route path="/login" component={Login}/>
          <Route path="/user-activation" component={UserActivation}/>
          <Layout>
            <Route exact path="/"><Redirect to="/users"/></Route>
            <Route exact path="/users" component={UserPage}/>
            <Route exact path="/users/:sub/edit" component={UserForm}/>
            <Route exact path="/invite-user" component={UserForm}/>
            <Route exact path="/organisations" component={OrganisationPage}/>
            <Route exact path="/organisations/:tenantId" component={OrganisationPage}/>
            <Route exact path="/organisations/:tenantId/new" component={(props: any) => (
              <OrganisationForm {...props} key={window.location.pathname}/>
            )}/>
            <Route exact path="/organisations/:tenantId/edit" component={OrganisationForm}/>
          </Layout>
        </Switch>
      </QueryClientProvider>
    </Router>
  );

};

export default App;
