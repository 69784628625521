const findTenantById = (tenants, id, path = []) => {
  if (! tenants) return null;
  tenants.path = path.concat({id: tenants.node.id, text: tenants.node.name, isTenant: true});
  if (tenants.node.id === id) return tenants;

  for (const child of tenants.children) {
    const res = findTenantById(child, id, tenants.path);
    if (res) return res;
  }
};

export default findTenantById;
