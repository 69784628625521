import clsx from "clsx";
import { useState } from 'react'
import { RadioGroup } from '@headlessui/react'

export const InputRadio = ({ options, value, label, handleChange, name, disabled = false }) => {
  const selected = options.find(o => o.id === value) || {};
  
  return (
    <RadioGroup disabled={disabled} className={disabled ? "opacity-50 cursor-not-allowed" : ""} value={selected} onChange={(e) => handleChange(e.id, name)}>
      <RadioGroup.Label>{label}</RadioGroup.Label>
      <div className="bg-white rounded-md -space-y-px ">
        {options.map((option, optionIdx) => (
          <RadioGroup.Option
            key={option.name}
            value={option}
            className={({ checked }) =>
              clsx(
                optionIdx === 0 ? 'rounded-tl-md rounded-tr-md' : '',
                optionIdx === options.length - 1 ? 'rounded-bl-md rounded-br-md' : '',
                checked ? 'bg-indigo-50 border-indigo-200 z-10' : 'border-gray-200',
                'relative border p-4 flex cursor-pointer focus:outline-none'
              )
            }
          >
            {({ active, checked }) => (
              <>
                <span
                  className={clsx(
                    checked ? 'bg-indigo-600 border-transparent' : 'bg-white border-gray-300',
                    active ? 'ring-2 ring-offset-2 ring-indigo-500' : '',
                    'h-4 w-4 mt-0.5 cursor-pointer rounded-full border flex items-center justify-center'
                  )}
                  aria-hidden="true"
                >
                  <span className="rounded-full bg-white w-1.5 h-1.5" />
                </span>
                <div className="ml-3 flex flex-col">
                  <RadioGroup.Label
                    as="span"
                    className={clsx(checked ? 'text-indigo-900' : 'text-gray-900', 'block text-sm font-medium')}
                  >
                    {option.name}
                  </RadioGroup.Label>
                  <RadioGroup.Description
                    as="span"
                    className={clsx(checked ? 'text-indigo-700' : 'text-gray-500', 'block text-sm')}
                  >
                    {option.description}
                  </RadioGroup.Description>
                </div>
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  )
};
