import React, { useState } from "react";
import get from "lodash.get";
import Avatar from "../Avatar";
import ResendModal from "../Modal/ResendModal";
import Success from "../Alert/Success";
import { useHistory } from "react-router-dom";
import {getInitials} from "../../helpers/users";
import {getTenantsFlat} from "../../api";
import {useQuery} from "react-query";
import {useMutation} from "react-query";
import * as api from "../../api";
import PageLoader from "../PageLoader";
import { externalIdentityProvider } from "../../helpers/tenantComponents";

const UserCard = ({ users, isAllUsers, tenantId }) => {
  const [userToResend, setUserToResend] = useState();
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const { mutateAsync: resendInvite, ...resendInviteQuery } = useMutation(api.resendInvite, {
    onSuccess: () => setShowSuccessAlert(true)
  });
  const { push } = useHistory();
  const query = useQuery('tenantsFlat', getTenantsFlat);
  const tenants = get(query, "data.data");

  if (resendInviteQuery.isLoading) return <PageLoader fullPage />;
  return (
    <section aria-labelledby="user_card">
      <Success message="Successfully resent invitation" show={showSuccessAlert} setShow={setShowSuccessAlert}/>
      <ResendModal resendInvite={resendInvite} userToResend={userToResend} open={Boolean(userToResend)} setOpen={setUserToResend} />
      <div className="bg-white pt-6 shadow sm:rounded-md sm:overflow-hidden">
        <div className="px-4 flex justify-between sm:px-6">
          <h2 id="user_card" className="text-lg leading-6 font-medium text-gray-900">
            {isAllUsers ? "All users" : "Organisation members"}
          </h2>
          <button
            className="bg-indigo-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={() => push(isAllUsers ? "/invite-user" : `/invite-user?parentId=${tenantId}`)}
          >
            Invite new user
          </button>
        </div>
        <div className="mt-6 flex flex-col">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className="overflow-hidden border-t border-gray-200">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Source
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Role
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Organisation
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Status
                    </th>
                    <th scope="col" className="relative px-6 py-3">
                      <span className="sr-only ">Edit</span>
                    </th>
                  </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                  {users.map((user, i) => (
                    <tr key={user.email}>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center">
                          <div className="flex-shrink-0 h-10 w-10">
                            <Avatar initials={getInitials(user || "JL")} index={i} />
                          </div>
                          <div className="ml-4">
                            <div className="text-sm font-medium text-gray-900">{user.firstName + " " + user.lastName}</div>
                            <div className="text-sm text-gray-500">{user.email}</div>
                          </div>
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {(user.identityProvider === "internal") ? "Internal" : externalIdentityProvider?.displayName}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{user.role}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{tenants?.find(t => t.id === user.tenantId)?.name}</td>
                      <td className="px-6 py-4 whitespace-nowrap">
                      {
                        (() => {
                          if (! user.status || user.status === "FORCE_CHANGE_PASSWORD")
                            return <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800">Pending</span>;
                          if (user.status === "RESET_REQUIRED")
                            return <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800">Reset required</span>;
                          if (user.enabled)
                            return <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">Active</span>;
                          else
                            return <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">Disabled</span>
                        })()
                      }
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                        {
                          (() => {
                            if (user.status === "FORCE_CHANGE_PASSWORD")
                              return <a className="text-indigo-600 cursor-pointer hover:text-indigo-900" onClick={() => setUserToResend(user)}>Resend</a>
                            else if (user.identityProvider !== "internal" && ! user.enabled)
                              return <a className="text-gray-400">Edit</a>;
                            else
                              return <a onClick={() => push(`/users/${user.sub}/edit`)} className="text-indigo-600 cursor-pointer hover:text-indigo-900">Edit</a>;
                          })()
                        }
                      </td>
                    </tr>
                  ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default UserCard;