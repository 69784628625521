import clsx from "clsx";
import React from "react";

const sizes = {
  small: "w-4 h-4 border-2",
  normal: "w-12 h-12 border-4",
};

const Loader = ({ className, size }) => (
  <div
    className={clsx("border-indigo-500 rounded-full animate-spin", sizes[size], className)}
    data-testid="loader"
    style={{ borderTopColor: "#868e96" }}
  />
);

export default Loader;
