import React from "react";
import PageLoader from "../PageLoader";
import useQueryHooks from "../../helpers/useQueryHooks";
import OrganisationCard from "./OrganisationCard";
import UserCard from "../User/UserCard";

const OrganisationPage = () => {
  let { isLoading, tenant } = useQueryHooks();
  if (isLoading) return <PageLoader/>;

  return (
    <div className="space-y-6">
      <OrganisationCard tenant={tenant} />
      <UserCard users={tenant.users} tenantId={tenant.id} />
    </div>
  );
};

export default OrganisationPage;
