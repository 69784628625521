import React from "react";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import useQueryHooks from "../../helpers/useQueryHooks";

const OrganisationCard = () => {
  const { tenant } = useQueryHooks();
  const { push } = useHistory();

  return (
    <div className={clsx("bg-white shadow overflow-hidden sm:rounded-md")}>
      <div className="px-4 py-5 flex justify-between sm:px-6">
        <div>
          <h3 className="text-lg leading-6 font-medium text-gray-900">Organisation Details</h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">Information and settings for the organisation.</p>
        </div>
        <a onClick={() => push(`/organisations/${tenant?.id}/edit`)} className="text-indigo-600 cursor-pointer hover:text-indigo-900">
          Edit
        </a>
      </div>
      <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
        <dl className="sm:divide-y sm:divide-gray-200">
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Organisation Name</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{tenant?.name}</dd>
          </div>
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Address</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {tenant?.address ? `${tenant?.address.addressLine1}, ${tenant?.address.addressLine2}, ${tenant?.address.city}, ${tenant?.address.postCode}, ${tenant?.address.country}` : 'n/a'}
           </dd>
          </div>
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">UMR</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{tenant?.umr ?? 'n/a'}</dd>
          </div>
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Type</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{tenant?.type ?? 'n/a'}</dd>
          </div>
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Organisation commission</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{tenant?.commission !== null && tenant?.commission !== undefined ? `${tenant?.commission}%` : 'n/a'}</dd>
          </div>
        </dl>
      </div>
    </div>
  )
};

export default OrganisationCard;
